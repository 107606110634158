import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBl98bh3WOmu3h2KvZ7-HvTMACZX8gJbC4",
    authDomain: "mirage-storage-test.firebaseapp.com",
    projectId: "mirage-storage-test",
    storageBucket: "mirage-storage-test.appspot.com",
    messagingSenderId: "841637315448",
    appId: "1:841637315448:web:421fbe70592e794217be8d",
    measurementId: "G-HCX8MTFTJT"
  };

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };
